<template>
  <div class="footer">
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 sm-margin-30px-bottom">
            <img alt="footer-logo" src="img/logos/logodog.png" />
            <p class="margin-20px-top">
              Watchdog Security is your one-stop shop for all
              your home and commercial security needs.
            </p>
         <!--   <div class="margin-25px-top footer-social-icons">
              <ul>
                <li>
                  <a href="#!"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li>
                  <a href="#!"><i class="fab fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#!"><i class="fab fa-instagram"></i></a>
                </li>
                <li>
                  <a href="#!"><i class="fab fa-youtube"></i></a>
                </li>
                <li>
                  <a href="#!"><i class="fab fa-linkedin-in"></i></a>
                </li>
              </ul>
            </div> -->
          </div>

          <div class="col-lg-4 col-md-6 sm-margin-30px-bottom">
            <h3 class="footer-title-style2 text-theme-color">Houston / Spring TX</h3>
            <ul class="footer-list">
              <li>
                <span class="d-inline-block vertical-align-top font-size18"
                  ><i class="fas fa-map-marker-alt text-theme-color"></i
                ></span>
                <span class="d-inline-block width-85 vertical-align-top padding-10px-left"
                  >330 Rayford Rd. #207 <br> Spring, TX 77386</span>
              </li>
              <li>
                <span class="d-inline-block vertical-align-top font-size18">
                  <i class="fas fa-mobile-alt text-theme-color"></i>
                </span>
                <span class="d-inline-block width-85 vertical-align-top padding-10px-left">
                  713-900-9400</span>
              </li>
              <li>
                <span class="d-inline-block vertical-align-top font-size18"
                  ><i class="far fa-envelope text-theme-color"></i
                ></span>
                <span class="d-inline-block width-85 vertical-align-top padding-10px-left">
                  support@watchdog-security.net</span>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 offset-lg-1">
            <h3 class="footer-title-style2 text-theme-color">Florida West Coast</h3>
            <ul class="footer-list">
              <li>
                <span class="d-inline-block vertical-align-top font-size18">
                  <i class="fas fa-map-marker-alt text-theme-color"></i>
                  </span>
                <span class="d-inline-block width-85 vertical-align-top padding-10px-left">
                  14621 State Road 70E #345 <br> Bradenton, FL 34202
                  </span>
              </li>
              <li>
                <span class="d-inline-block vertical-align-top font-size18">
                  <i class="fas fa-mobile-alt text-theme-color"></i>
                </span>
                <span class="d-inline-block width-85 vertical-align-top padding-10px-left">
                  305-707-9400
                </span>
              </li>
              <li>
                <span class="d-inline-block vertical-align-top font-size18">
                  <i class="fas fa-globe text-theme-color"></i>
                </span>
                <span class="d-inline-block width-85 vertical-align-top padding-10px-left">
                  www.watchdog-security.net
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-bar xs-font-size13">
        <div class="container">
          <div class="row">
            <div class="col-md-6 text-left xs-text-center xs-margin-5px-bottom">
              <b>a division of TranzNet Security LLC B-17079 & ACR-1713006</b>
              <p>
                &copy; Copyright Watchdog Security. All Rights Reserved.
              </p>
            </div>
            <div class="col-md-6 text-right xs-text-center">
              Design and Developed by: SLFEMP
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>
