<template>
  <div class="services">
    <!-- start blog section -->
    <section>
      <div class="container">
        <div class="row">
          <div class="col-lg-6 margin-30px-bottom sm-margin-20px-bottom">
            <div class="case-block">
              <div class="row">
                <div class="col-md-6 col-xs-12">
                  <img src="img/securitycameras.jpg" alt="case" />
                </div>
                <div class="col-md-6 col-xs-12">
                  <div class="case-block-inner">
                    <h4>Security Camera Systems</h4>
                    <p>
                     Protect your home or business with our custom
                     security camera systems. Monitor your property
                     from your phone or other devices. We will design
                     and implement a your system to your exact specifications.
                    </p>
                      <router-link to="/securitycameras" tag="a">learn more</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 margin-30px-bottom sm-margin-20px-bottom">
            <div class="case-block">
              <div class="row">
                <div class="col-md-6 col-xs-12">
                  <img src="img/homealarm.jpg" alt="case" />
                </div>
                <div class="col-md-6 col-xs-12">
                  <div class="case-block-inner">
                    <h4>Alarm Systems</h4>
                    <p>
                      Secure your home or business with our custom alarm
                      systems designed specifically for your security needs.
                    </p>
                     <router-link to="/alarmsystems" tag="a">learn more</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 margin-30px-bottom sm-margin-20px-bottom">
            <div class="case-block">
              <div class="row">
                <div class="col-md-6 col-xs-12">
                  <img src="img/structuredcable.jpg" alt="case" />
                </div>
                <div class="col-md-6 col-xs-12">
                  <div class="case-block-inner">
                    <h4>Structured/Network Cabling</h4>
                    <p>
                      We will plan and execute a structured cabling system
                      that's optimized to provide reliable communications
                    </p>
                       <router-link to="/structuredcabling" tag="a">learn more</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 margin-30px-bottom sm-margin-20px-bottom">
            <div class="case-block">
              <div class="row">
                <div class="col-md-6 col-xs-12">
                  <img src="img/audiovideo.jpg" alt="case" />
                </div>
                <div class="col-md-6 col-xs-12">
                  <div class="case-block-inner">
                    <h4>Audio/Video Systems</h4>
                    <p>
                      We will custom tailor a high-quality home
                      system to meet your specific taste and lifestyle.
                    </p>
                      <router-link to="/audiovideo" tag="a">learn more</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 sm-margin-20px-bottom">
            <div class="case-block">
              <div class="row">
                <div class="col-md-6 col-xs-12">
                  <img src="img/homewatch.jpg" alt="case" />
                </div>
                <div class="col-md-6 col-xs-12">
                  <div class="case-block-inner">
                    <h4>Home Watch Services</h4>
                    <p>
                      Our professional agents conducts scheduled visual reports of
                      your rent property or 2nd home on a reaccuring basis.
                    </p>
                    <router-link to="/homewatch" tag="a">learn more</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="case-block">
              <div class="row">
                <div class="col-md-6 col-xs-12">
                  <img src="img/itservices.jpg" alt="case" />
                </div>
                <div class="col-md-6 col-xs-12">
                  <div class="case-block-inner">
                    <h4>IT Services</h4>
                    <p>
                      Let us take responsibility for supporting and your IT
                       environment so you can focus on your business and what you do best.
                    </p>
                    <router-link to="/itservices" tag="a">learn more</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end blog section -->
  </div>
</template>

<script>
export default {
  name: 'Services',
};
</script>
