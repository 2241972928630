<template>
  <div class="securitycameras">

      <!-- start page title section -->
      <section class="page-title-section2 bg-img cover-background"
      data-overlay-dark="2"
      style="background-image: url('/img/securitysystembanner.jpg')">
          <div class="container">

         <div class="row">
                  <div class="col-md-12">
                    <div style="position:relative; top:-50px;">
                      <h1>Home Watch Services</h1>
                    </div>
                  </div>
              </div>

          </div>
      </section>

<section class="lg">
    <div class="container">
        <div class="row">

        <div class="col-5">
          <serviceside></serviceside>

         </div>

         <div class="col-7">
           <h3>Who is watching your property while you are away?</h3>

           <p>
             Stop worrying about your property when you are away.
             Watchdog provides home watch services and property
             management for traveling home owners, AirBnb property rentals,
             snowbirds, and real estate investments.
           </p>

            <ul>
              <li>Weekly Inspection Services</li>
              <li>Inpection after AirBnB Rental</li>
              <li>Storm Damage</li>
              <li>Forced Entry</li>
              <li>Vandalism</li>
              <li>Hurricane / Storm preparation</li>
              <li>Flooding</li>
              <li>Moisture Problems</li>
              <li>Plumbing Failures</li>
              <li>Animal or Pest Invasion</li>
              <li>Doors / Windows Locked</li>
              <li>Removal of Mail and Newspapers</li>
              <li>Emergencies</li>
            </ul>

            <b>Call us today at (Houston) 713-900-9400
              or (Florida West Coast) 305-707-9400  to see why we are the
               leading company in home protection.</b>
         </div>

         </div>
    </div>
</section>

  </div>
</template>

<script>

import Serviceside from '../components/Serviceside.vue';

export default {
  name: 'Homewatch',
  components: {
    Serviceside,
  },

};
</script>
<style scoped>

</style>
