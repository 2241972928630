<template>
  <div class="services">

      <div class="container">
        <div class="row">
        <div class="col-12">
          <h5>SERVICES</h5>
        </div>
        <div class="col-12">
        <ul class="list-style-4" style="font-size:18px;">
          <li style="border-bottom:1px solid #ccc; padding-bottom:10px; margin-bottom:20px;">
            <router-link to="/securitycameras" tag="a"> Security Cameras</router-link></li>
          <li style="border-bottom:1px solid #ccc; padding-bottom:10px; margin-bottom:20px;">
            <router-link to="/alarmsystems" tag="a"> Alarm Systems</router-link></li>
          <li style="border-bottom:1px solid #ccc; padding-bottom:10px; margin-bottom:20px;">
            <router-link to="/itservices" tag="a"> IT Services</router-link></li>
          <li style="border-bottom:1px solid #ccc; padding-bottom:10px; margin-bottom:20px;">
            <router-link to="/audiovideo" tag="a"> Audio / Video</router-link></li>
          <li style="border-bottom:1px solid #ccc; padding-bottom:10px; margin-bottom:20px;">
            <router-link to="/structuredcabling" tag="a"> Structured Cabling</router-link></li>
          <li style="border-bottom:1px solid #ccc; padding-bottom:10px; margin-bottom:20px;">
            <router-link to="/homewatch" tag="a"> Home Watch</router-link></li>
        </ul>
        </div>
        </div>
      </div>

  </div>
</template>

<script>
export default {
  name: 'Serviceside',
};
</script>
