<template>
  <div class="carousel">
    <!-- Start banner Section -->
    <section
      class="parallax cover-background screen-height"
      data-overlay-dark="0"
      data-background="./img/homebanner.jpg"
    >
      <div class="container height-100">
        <div class="display-table height-100 width-100">
          <div class="display-table-cell vertical-align-middle caption">
            <div class="overflow-hidden width-80 sm-width-85 xs-width-95">
              <h1 class="font-size50 md-font-size42 sm-font-size38 font-weight-800"
            style="color:#fff" >
              Who's Watching <br> Your Investments.
              </h1>
              <div
                class="text-black line-height-normal font-weight-400 font-size36 md-font-size32
                sm-font-size margin-20px-bottom no-letter-spacing width-60 xs-width-70"
                style="color:#fff"
              >
                Protect Your Business And Home With Watchdog Security.
              </div>
              <p
                class="width-60 xs-width-100 font-size16 xs-display-none
                line-height-30 xs-font-size14 xs-line-height-26"
                style="color:#fff"
              >
                <b>Trusted, Professional, Security Services</b>
              </p>
            <!--  <div class="margin-30px-top sm-margin-25px-top">
                <a href="/about" class="butn theme"><span>Learn More</span></a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end banner Section -->
  </div>
</template>

<script>
export default {
  name: 'Carousel',
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
