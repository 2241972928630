<template>
  <div class="securitycameras">
    <section>
      <div class="container">
        <div class="row">
          <h1>Contact</h1>
            <div class="container-fluid no-padding">
              <div class="section-heading">
                <h3>Florida West Coast </h3>
               <h6>Ph: 305-707-9400</h6>
               <p>14621 State Road 70E #345 <br> Bradenton, FL 34202</p>
              </div>

              <div class="row position-relative">
                <!-- start map section -->
                <iframe
                  class="contact-map col-md-12"
                  id="gmap_canvas"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3541.2030706313258!2d-82.39729678920024!3d27.43178137624297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c339f939194755%3A0x7754c7ab21e2499d!2s14621%20State%20Road%2070%20E%2C%20Bradenton%2C%20FL%2034202!5e0!3m2!1sen!2sus!4v1692031978379!5m2!1sen!2sus"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
                <!-- end map section -->
              </div>
            </div>

            <div class="container-fluid no-padding">
              <div class="section-heading" style="margin-top:100px;">
                <h3>Houston, Texas</h3>
                <h6>Ph: 713-900-9400</h6>
              </div>
              <div class="row position-relative">
                <!-- start map section -->
                <iframe
                  class="contact-map col-md-12"
                  id="gmap_canvas"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d312555.3256851306!2d-95.62308612305397!3d30.057476041062216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864734326feb980f%3A0x437b1f9fe30d3729!2s330%20Rayford%20Rd%20%23207%2C%20Spring%2C%20TX%2077386!5e0!3m2!1sen!2sus!4v1607918854344!5m2!1sen!2sus"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
                <!-- end map section -->
              </div>
            </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  components: {},
};
</script>
<style scoped></style>
