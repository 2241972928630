<template>
  <div class="securitycameras">

      <!-- start page title section -->
      <section
      class="page-title-section2 bg-img cover-background"
      data-overlay-dark="1" style="background-image: url('/img/itservicesbanner.jpg')">
          <div class="container">

             <div class="row">
                  <div class="col-md-12">
                    <div style="position:relative; top:-50px;">
                      <h1>IT Services</h1>
                    </div>
                  </div>
              </div>

          </div>
      </section>

      <section class="lg">
          <div class="container">
          <div class="row">

              <div class="col-5">
                  <ul class="list-style-4">
                      <serviceside></serviceside>
                    </ul>
              </div>

              <div class="col-7">
                <h3>IT Services through our sister company TranzNet Consulting</h3>
                 <p>
                  TranzNet is primarily a support organization that
                  provides all levels of support and services ranging
                  from incident-based support to long-term service contracts.
                  Additionally, TranzNet provides hardware solutions above
                  and beyond the ones found at other service-orientated IT
                  companies in the market.
                  </p><p>
                  Analyzing your total IT needs, TranzNet consultants diagram the necessary
                  infrastructure and specialize in developing a long-term IT plan to give
                  any sized business the opportunity to have technology support and
                  maintenance 24/7 at a fraction of the cost. We replace your full-time
                  resource and become your IT expert, all for a part-time cost. For more
                  details on our specific services, please visit our services page.
                  </p>
                  <h5>Services</h5>
                  <ul>
                    <li>On-site & Remote support</li>
                    <li>Emergency Support - whenever you need it</li>
                    <li>Troubleshooting Microsoft and other systems</li>
                    <li>Proactive 24/7 Network Monitoring</li>
                    <li>Data Recovery</li>
                    <li>Network Security</li>
                    <li>Building Computer Networks</li>
                    <li>Optimizing Servers for Best Performance</li>
                    <li>Consulting on Your Business’ Technology Needs</li>
                    <li>Virus Removal</li>
                    <li>Staff Training</li>
                    <li>Hardware Purchasing</li>
                </ul>

            <b>Call us today at (Houston) 713-900-9400
              or (Florida West Coast) 305-707-9400  to see why we are the
               leading company in home protection.</b>
              </div>

          </div>
          </div>
      </section>

  </div>
</template>

<script>

import Serviceside from '../components/Serviceside.vue';

export default {
  name: 'Alarmsystems',
  components: {
    Serviceside,
  },

};
</script>
<style scoped>

</style>
