<template>
  <div class="securitycameras">

      <!-- start page title section -->
      <section class="page-title-section2 bg-img cover-background"
      data-overlay-dark="2"
      style="background-image: url('/img/audiovideobanner.jpg')">
          <div class="container">

         <div class="row">
                  <div class="col-md-12">
                    <div style="position:relative; top:-50px;">
                      <h1>Audio / Video Services</h1>
                    </div>
                  </div>
              </div>

          </div>
      </section>

<section class="lg">
    <div class="container">
        <div class="row">

        <div class="col-5">
          <serviceside></serviceside>

         </div>

         <div class="col-7">
           <h3>General Audio / Video Services</h3>

          <p>
          From home theatre, multi-room music, outdoor Entertainment Our
          AV professionals can install televisions,
          speakers, home theater systems, home audio,
          multi-room systems, ooutdoor entertainment as well as
          full home audio and more.
          </p>

            <ul class="list-style-3">
              <li>Home Theaters and Media Rooms</li>
              <li>Television Mounting</li>
              <li>High Performance Audio Systems</li>
              <li>Surveillance Systems</li>
              <li>Video Projection and Conferencing</li>
              <li>Commercial Audio and Video</li>
              <li>Computer Networking and VOIP Phone Systems</li>
              <li>HVAC and Lighting Solutions</li>
            </ul>
<p></p>
            <b>Call us today at (Houston) 713-900-9400 or
              (Florida Keys) 305-707-9400 to schedule a consultation.</b>
         </div>

         </div>
    </div>
</section>

  </div>
</template>

<script>

import Serviceside from '../components/Serviceside.vue';

export default {
  name: 'Audiovideo',
  components: {
    Serviceside,
  },

};
</script>
<style scoped>

</style>
