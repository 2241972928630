<template>
  <div class="securitycameras">

      <!-- start page title section -->
      <section class="page-title-section2 bg-img cover-background"
      data-overlay-dark="2"
      style="background-image: url('/img/securitysystembanner.jpg')">
          <div class="container">

         <div class="row">
                  <div class="col-md-12">
                    <div style="position:relative; top:-50px;">
                      <h1>Surveillance Camera Systems</h1>
                    </div>
                  </div>
              </div>

          </div>
      </section>

<section class="lg">
    <div class="container">
        <div class="row">

        <div class="col-5">
          <serviceside></serviceside>

         </div>

         <div class="col-7">
           <h3>Who Is Watching Your Investments?</h3>
            <p>Watchdog Security offers a comprehensive selection of video security
              cameras, and many other specialty CCTV cameras. Each video security
              camera has a specific use, designed to meet the unique needs of virtually
              any video surveillance application - and any budget. Find the video security
              cameras that are perfect for your needs by contacting our trained
              sales consultants for expert advice.</p>

                <ul class="list-style-2">
                  <li>Surveillance Camera Systems</li>
                  <li>Commercial & Residential Alarms</li>
                  <li>Access Control Systems</li>
                  <li>and much more!</li>
              </ul>

            <p>Watchdog Security believes that our clients aren't just customers,
              they're neighbors. We are a true local company
              dedicated to our community and our clients.
              By building relationships and providing superior service
              we are able to build an ever increasing client base.</p>

            <p>Watchdog Security allows you to gain a peace of mind and freedom that
            will work with your lifestyle, both, day in and day out.</p>

            <p>Whether you are looking to upgrade your existing
              security camera systems, or want to be provided
              with a full turn-key installation, we are sure
              that we can help you find a cost effective
              solution for your business or home.</p>

            <b>Call us today at (Houston) 713-900-9400
              or (Florida Keys) 305-707-9400  to see why we are the
               leading company in home protection.</b>
         </div>

         </div>
    </div>
</section>

  </div>
</template>

<script>

import Serviceside from '../components/Serviceside.vue';

export default {
  name: 'Securitycameras',
  components: {
    Serviceside,
  },

};
</script>
<style scoped>

</style>
