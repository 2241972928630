<template>
  <div class="structurecabling">

      <!-- start page title section -->
      <section class="page-title-section2 bg-img cover-background"
      data-overlay-dark="3"
       style="background-image: url('/img/structuredcablingbanner.jpg')">
          <div class="container">

         <div class="row">
                  <div class="col-md-12">
                    <div style="position:relative; top:-50px;">
                      <h1>Structured Cabling</h1>
                      <p style="color:#fff">
                        <b>The backbone of your wired and wireless network</b>
                        </p>
                    </div>
                  </div>
              </div>

          </div>
      </section>

<!-- start service section -->
<section class="lg">
    <div class="container">
        <div class="row">

          <div class="col-5">
             <serviceside></serviceside>
          </div>

          <div class="col-7">
            <p>
              As a structured cabling provider, Watchdog Security
              can help you plan and execute a
              reliable and optimized structured cable system.
              We design and deploy structured cabling
              infrastructure that supports your
              wireless and wired network.
            </p>

            <p>
              Every electronic device connected the internet
              or each other rely on a network.
              Security cameras, data systems, access control,
              alarm and surveillance systems all
              are only as reliable as your network. As
              backbone of your businesses communication,
              structured cabling ia a top priority
              for business continuity.
            <p>

          <ul class="list-style-4">
              <li>Cat 5, Cat 5E, Cat 6, Cat 6A, and Coax Data Cabling</li>
              <li>Multi-mode and single-mode fiber optic installation</li>
              <li>Fiber terminations (LC, FC, SC, ST)</li>
              <li>Raceway and Overhead cable rack and design and installation</li>
              <li>Structured Cabling Testing</li>
              <li>Structured Cabling Management</li>
              <li>Low Voltage Cabling</li>
              <li>Audio / Visual</li>
          </ul>

            <p>
              Whether you are when upgrading your current systems,
              building a new facility, or retrofitting a space
              Watchdog Security can design and deploy all
              of your structured cabling needs.
            </p>

          </div>
        </div>
    </div>
</section>
<!-- end service section -->

  </div>
</template>

<script>

import Serviceside from '../components/Serviceside.vue';

export default {
  name: 'Structurecabling',
  components: {
    Serviceside,
  },

};
</script>
<style scoped>

</style>
