import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Audiovideo from '../views/Audiovideo.vue';
import Structuredcabling from '../views/Structuredcabling.vue';
import Homewatch from '../views/Homewatch.vue';
import Itservices from '../views/Itservices.vue';
import Securitycameras from '../views/Securitycameras.vue';
import Alarmsystems from '../views/Alarmsystems.vue';
import Contact from '../views/Contact.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/structuredcabling',
    name: 'Structuredcabling',
    component: Structuredcabling,
  },
  {
    path: '/audiovideo',
    name: 'Audiovideo',
    component: Audiovideo,
  },
  {
    path: '/securitycameras',
    name: 'Securitycameras',
    component: Securitycameras,
  },
  {
    path: '/itservices',
    name: 'Itservices',
    component: Itservices,
  },
  {
    path: '/alarmsystems',
    name: 'Alarmsystems',
    component: Alarmsystems,
  },
  {
    path: '/homewatch',
    name: 'Homewatch',
    component: Homewatch,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
