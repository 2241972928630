<template>
  <div class="securitycameras">

      <!-- start page title section -->
      <section
      class="page-title-section2 bg-img cover-background"
      data-overlay-dark="2" style="background-image: url('/img/alarmbanner.jpg')"
       >
          <div class="container">

         <div class="row">
                  <div class="col-md-12">
                    <div style="position:relative; top:-50px;">
                      <h1> Alarm Systems</h1>
                    </div>
                  </div>
              </div>

          </div>
      </section>

<section class="lg">
    <div class="container">
        <div class="row">

        <div class="col-5">
          <serviceside></serviceside>
         </div>

         <div class="col-7">
           <h3>Protecting What Matters Most</h3>
            <p> Watchdog Security specializes in "Protecting What Matters the Most"
            your family and home. We have serviced the Houston area for over 15
            years and strive to offer the best customer service.</p>

            <p>Watchdog Security can handle all your security needs, from Residential
            Alarms to Commercial Alarms, Security Cameras, Home Automation, and more.
            With our professional sales team and technicians, we can provide you
            with a personalized security system that fits your needs within a timely manner.</p>

            <p>Watchdog Security allows you to gain a peace of mind and freedom that
            will work with your lifestyle, both, day in and
            day out.</p>

   <p></p>
              <h5>Local UL Monitoring</h5>
                <p>UL-approved central monitoring station.
              The station is active 24 hours a day, 7 days a week, 365 days a year.</p>

              <h5>You Own Your System</h5>
              <p>We don’t lease systems. Everything that
              we install in your business or home is yours.</p>

              <h5>Free Consultation</h5>
              <p>We are always happy to come out and meet our
              neighbors to perform a consultation. Your
              security needs can best be analyzed at your business or home.</p>

              <h5>Switching is Easy</h5>
              <p>If you already have a functional system and would
              like to change to Watchdog for your monitoring service,
              the process is a simple and inexpensive for most systems.
              There is a reprogramming, diagnostic and
              then the monthly monitoring fees.</p>

              <h5>Insurance Cost Reduction</h5>
              <p>Many insurance companies offer a discount for their
              clients that have monitored burglar and fire systems.
              Ask your insurance agent for details. We will provide
              you with a certificate to more easily facilitate your discount.</p>

              <p>*Watchdog Security offers Traditional Phone Line Monitoring,
              Cell/Wireless Monitoring, & Internet Monitoring via Alarm.com</p>

              <p>Integrated solutions so you can keep up with your
              security systems from your PC or Smart Phone</p>

              <ul class="list-style-4">
                  <li>Professional Installation</li>
                  <li>Fast Local Support</li>
                  <li>Save Up To 20% Homeowners Insurance Discount</li>
                  <li>No Phone Line Required</li>
              </ul>
              </div>
         </div>
    </div>
</section>
  </div>
</template>

<script>

import Serviceside from '../components/Serviceside.vue';

export default {
  name: 'Alarmsystems',
  components: {
    Serviceside,
  },

};
</script>
<style scoped>

</style>
